<template>
  <!-- HOME -->
  <v-app id="inspire" style="background-color: #dcebff">
    <v-row justify="center" no-gutters class="mb-16">
      <div class="header pb-16">
        <v-row class="mt-4" no-gutters>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="5">
            <v-img
              src="../../assets/Logo/SVG/WhiteYellow.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 120 : 200"
              class="has-show ml-3 mt-n1 mt-md-n3"
              eager
            ></v-img>
          </v-col>
          <v-col cols="6" sm="5">
            <div class="text-end mr-3">
              <v-btn
                color="darkpink"
                :large="$vuetify.breakpoint.smAndUp ? true : false"
                dark
                @click="toLogin()"
                class="px-5 px-sm-8 py-5 has-show rounded-lg"
              >
              {{ $t('home.home_login') }}
                <v-icon right>mdi-login</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <div
          class="
            text-h4 text-sm-h2 text-lg-h1
            white--text
            font-weight-medium
            text-center
            has-show
          "
        >
          <div>
            {{ $t('home.home_title.title1') }} <span class="font-weight-bold">{{ $t('home.home_title.title2') }}</span> <br />
            {{ $t('home.home_title.title3') }} <span class="font-weight-bold">{{ $t('home.home_title.title4') }}</span>
          </div>
        </div>

        <v-row
          v-if="$vuetify.breakpoint.mdAndUp"
          class="mt-6 has-show"
          align="center"
          align-content="center"
          justify="center"
        >
          <v-col cols="2">
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg"
              color="blue"
              height="300"
            >
              <v-img src="../../assets/Home-06.svg" eager> </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="2">
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg mb-6"
              color="darkyellow"
              height="175"
            >
              <v-img src="../../assets/Home-03.svg" eager> </v-img>
            </v-sheet>
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg"
              color="cyan"
              height="250"
            >
              <v-img src="../../assets/Home-02.svg" eager> </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="2">
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg"
              color="pink"
              height="500"
            >
              <v-img src="../../assets/Home-05.svg" eager> </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="2">
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg"
              color="darkblue"
              height="250"
            >
              <v-img src="../../assets/Home-01.svg" eager> </v-img>
            </v-sheet>
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg mt-6"
              color="yellow"
              height="175"
            >
              <v-img src="../../assets/Home-07.svg" eager> </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="2">
            <v-sheet
              elevation="5"
              class="d-flex rounded-lg"
              color="darkpink"
              height="300"
            >
              <v-img src="../../assets/Home-04.svg" eager> </v-img>
            </v-sheet>
          </v-col>
        </v-row>
        <div v-if="$vuetify.breakpoint.smAndDown" class="has-show">
          <v-img
            eager
            src="../../assets/HomeCell.svg"
            width="320"
            class="mx-auto my-6 my-sm-10"
          >
          </v-img>

          <div
            class="
              text-subtitle-1 text-sm-h4
              white--text
              font-weight-regular
              text-center
            "
          >
            {{ $t('home.home_conteudo.conteudo1') }} <br />
            {{ $t('home.home_conteudo.conteudo2') }}
          </div>
        </div>
      </div>

      <v-col cols="12">
        <v-img
          src="../../assets/Waves.svg"
          :aspect-ratio="$vuetify.breakpoint.smAndUp ? 5 / 1 : 2 / 1"
          :class="$vuetify.breakpoint.smAndUp ? 'mt-n5' : 'mt-n8'"
        ></v-img
      ></v-col>
      <!-- Matéria -->
      <v-col cols="12" md="11" lg="8" class="mt-4 mt-md-10">
        <v-row class="mx-1 mx-md-0">
          <v-col
            cols="12"
            sm="10"
            md="8"
            class="mx-auto"
            align-self="center"
            order="1"
          >
            <div
              class="text-pink font-weight-bold"
              :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-center'"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 2rem'
                  : 'font-size: 1.3rem'
              "
            >
              {{ $t('home.home_pergunta.title1') }}
              <span class="font-weight-black"> {{ $t('home.home_pergunta.title2') }} </span>
            </div>
            <div
              class="mt-2 mt-md-4 text-justify"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1rem'
              "
            >
              <div style="text-indent: 2rem">
                {{ $t('home.home_respostaDaPergunta.resposta1') }} <b>{{ $t('home.home_respostaDaPergunta.resposta2') }}</b> {{ $t('home.home_respostaDaPergunta.resposta3') }}
              </div>
              <div style="text-indent: 2rem" class="mt-2">
                {{ $t('home.home_respostaDaPergunta.resposta4') }} <b>{{ $t('home.home_respostaDaPergunta.resposta2') }}</b> {{ $t('home.home_respostaDaPergunta.resposta5') }} <b>{{ $t('home.home_respostaDaPergunta.resposta6') }}</b> {{ $t('home.home_respostaDaPergunta.resposta7') }} <b>{{ $t('home.home_respostaDaPergunta.resposta8') }}</b>,
                {{ $t('home.home_respostaDaPergunta.resposta9') }}
                <b>{{ $t('home.home_respostaDaPergunta.resposta10') }}</b>!
              </div>
            </div>
          </v-col>
          <v-col
            cols="4"
            class="mx-auto d-flex justify-center align-center"
            v-if="$vuetify.breakpoint.mdAndUp"
            order-md="2"
          >
            <v-img src="../../assets/Books.svg" width="100%" class="mx-auto" />
          </v-col>
          <v-col
            cols="12"
            class="mx-auto text-center my-8 my-md-16"
            order="2"
            order-md="3"
          >
            <div
              class="text-yellow font-weight-medium"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 2rem'
                  : 'font-size: 1.3rem'
              "
            >
              {{ $t('home.home_plataformas.plataforma1') }}
              <span class="font-weight-black"> {{ $t('home.home_plataformas.plataforma2') }} </span> {{ $t('home.home_plataformas.plataforma3') }}
              <span class="font-weight-black"> {{ $t('home.home_plataformas.plataforma4') }} </span>
            </div>
            <v-img
              src="../../assets/Responsive.png"
              :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
              class="mx-auto mt-4"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="5"
            class="mx-auto text-center text-blue my-md-16"
            align-self="center"
            order="3"
            order-md="4"
          >
            <div
              class="font-weight-black"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 2rem'
                  : 'font-size: 1.3rem'
              "
            >
              {{ $t('home.home_aprenda.aprenda1') }}
            </div>
            <div
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1rem'
              "
              class="text-uppercase"
            >
              {{ $t('home.home_aprenda.aprenda2') }}
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="7"
            class="mx-auto mb-8 my-md-16 d-flex justify-center align-center"
            order="5"
            order-md="6"
          >
            <v-img src="../../assets/Games.svg" class="mx-auto" width="100%" />
          </v-col>

          <v-col cols="12" class="mx-auto text-center" order="6" order-md="7">
            <div
              class="font-weight-medium text-pink"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 2rem'
                  : 'font-size: 1.3rem'
              "
            >
              {{ $t('home.home_personalizacao.personalizacao1') }} <span class="font-weight-black"> {{ $t('home.home_personalizacao.personalizacao2') }}</span> {{ $t('home.home_personalizacao.personalizacao3') }} <span class="font-weight-black"> {{ $t('home.home_personalizacao.personalizacao4') }} </span>!
            </div>

            <v-img
              src="../../assets/Profile.svg"
              :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
              class="mx-auto"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="7"
            class="mt-8 my-md-16"
            order="8"
            order-md="8"
          >
            <v-img
              src="../../assets/Events.svg"
              class="mx-auto mt-n12 mt-md-0"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="5"
            class="mx-auto text-center mt-8 mt-md-0"
            align-self="center"
            order="7"
            order-md="9"
          >
            <div
              class="text-blue font-weight-medium"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 2rem'
                  : 'font-size: 1.3rem'
              "
            >
              {{ $t('home.home_eventos.eventos1') }}
              <span class="font-weight-black">{{ $t('home.home_eventos.eventos2') }}</span>!
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-footer dark padless class="mt-6 mt-md-16">
      <v-card flat tile style="width: 100%">
        <v-row class="darkblue px-4" no-gutters>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="4" sm="3" md="2">
            <v-img
              src="../../assets/Logo/SVG/WhiteYellow.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 120 : 150"
              class="mx-auto my-6 my-md-4"
            ></v-img>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="6"
            lg="5"
            class="text-end d-flex"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ' flex-column'
                : 'justify-space-between'
            "
            align-self="center"
            style="opacity: 0.9"
          >
            <div>
              <a
                href="./faq"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_duvidas') }}
              </a>
            </div>

            <v-divider
              color="white"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>
            <div>
              <a
                href="/termsofuse"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('hometeacher.hometeacher_termo.termo2') }}
              </a>
            </div>

            <v-divider
              color="#ffffff"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>
            <div>
              <a
                href="/privacypolicy"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_termPriv') }}
              </a>
            </div>

            <v-divider
              color="white"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>
            <div>
              <v-icon small>mdi-whatsapp</v-icon>
              <a
                href="https://wa.me/551935736050"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_suporte') }}
              </a>
            </div>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        </v-row>

        <v-card-text
          class="py-2 white--text text-center"
          style="background-color: #0d3d70"
        >
          {{ new Date().getFullYear() }} -
          <strong>{{ $t('home.home_respostaDaPergunta.resposta2') }}</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <v-snackbar
      right
      bottom
      v-model="cookiesBar"
      max-width="20%"
      hide-overlay
      persistent
      light
      timeout="-1"
      :transition="
        $vuetify.breakpoint.smAndUp
          ? 'scroll-x-reverse-transition'
          : 'scroll-y-reverse-transition'
      "
      color="rgba(255,255,255, 0.9)"
    >
      <v-img
        src="../../assets/Cookies.svg"
        class="mx-auto mb-6 my-2"
        max-width="100px"
      />
      <v-btn
        absolute
        top
        right
        icon
        color="darkcookie"
        @click="eraseCookie('accepted')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="font-weight-bold darkcookie--text text-h6">
        {{ $t('home.home_cookies.cookies1') }}
      </div>
      <div class="text-body-2 mt-2" style="color: #993022">
        {{ $t('home.home_cookies.cookies2') }}
        <a
          href="/privacypolicy"
          class="darkcookie--text font-weight-medium"
        >
          {{ $t('home.home_cookies.cookies3') }}
        </a>
      </div>

      <div class="d-flex justify-end">
        <v-btn
          rounded
          class="mt-4 mt-sm-2 mr-2 darkcookie--text elevation-0"
          @click="eraseCookie('accepted')"
        >
          {{ $t('botoes.botao_rejeitar') }}
        </v-btn>
        <v-btn
          rounded
          color="#FFA84266"
          class="mt-4 mt-sm-2 darkcookie--text elevation-0"
          @click="setCookie('accepted', 'true', '15')"
        >
          {{ $t('botoes.botao_aceitar') }}
        </v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      courses: [],
      cookiesBar: false,
    };
  },

  created() {
    this.verifyCookie();
    this.getCourses();
  },

  methods: {
    verifyCookie() {
      let a = this.getCookie("accepted");
      if (a) this.cookiesBar = false;
      if (!a)
        setTimeout(() => {
          this.cookiesBar = true;
        }, 3000);
    },
    toLogin() {
      this.$router.push("/login");
    },
    getCourses() {
      axios
        .get(`${url}/coursesHome`)
        .then((response) => {
          this.courses = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setCookie(name, key, days) {
      var validate = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        validate = "; Expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (key || "") + validate + "; path=/";

      this.cookiesBar = false;
    },

    getCookie(name) {
      var nameCookie = name + "=";
      var ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameCookie) == 0)
          return c.substring(nameCookie.length, c, length);
      }
      return null;
    },

    eraseCookie(name) {
      this.cookiesBar = false;
      document.cookie =
        name + "=; path=/; Expires=thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  align-self: flex-end;
}

.header {
  background: #0d3d70;
  background-size: cover;
  width: 100%;
}

.barra {
  width: 65%;
  height: 2px;
  margin: 0 auto;
  border-radius: 1rem;
  border: 0px;
}

.image-border {
  border: solid 5px #197cff;
  border-radius: 5px;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.404));
  transition: all 200ms;

  &:hover {
    border: solid 5px #0e4887;
    transform: rotate3d(1, 1, 1, 15deg) scale(1.1);
  }
}

.has-show {
  animation: has-show 2s cubic-bezier(0, 0.55, 0.45, 1) forwards;
}

@keyframes has-show {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.text-pink {
  color: #fd80cd;
  -webkit-text-stroke: 1px #e245a6;
}

.text-yellow {
  color: #f9c228;
  -webkit-text-stroke: 1px #cf9c0e;
}

.text-blue {
  color: #1670e8;
  -webkit-text-stroke: 1px #0e4887;
}

a {
  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.scale-up-center:hover {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
